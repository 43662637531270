import './App.css';
import { BrowserRouter as Router, HashRouter } from "react-router-dom"
import Routing from './routes';
import './assets/vendors/bootstrap/css/bootstrap.min.css'
import './assets/vendors/fontawesome/css/all.min.css'
import './assets/vendors/jarallax/jarallax.css'
import './assets/vendors/animate/animate.min.css'
import './assets/vendors/owl-carousel/assets/owl.carousel.min.css'
import './assets/vendors/owl-carousel/assets/owl.theme.default.min.css'
import './assets/vendors/cretech-icons/style.css'
import './assets/vendors/youtube-popup/youtube-popup.css'
import './assets/css/cretech.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { Server } from './Server';

axios.defaults.baseURL = Server
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.withCredentials = false


function App() {
  return (
    <>
    <HashRouter>
    <ToastContainer />
      <Routing />
    </HashRouter>
    </>
  );
}

export default App;
