
const globaldataReducer = (state, action) => {
    if(action.type === "WEBSITE_SETTINGS"){
        return {
            logo: action.payload.logo,
            email: action.payload.email,
            phone_no: action.payload.phone_no,
            address: action.payload.address,
            copyright: action.payload.copyright,
            footer_logo: action.payload.footer_logo,
            footer_info_text: action.payload.info_text,
            our_services: action.payload.our_services,
            social_icons: action.payload.social_icons,
            navitems: action.payload.nav_items,
        }
    }
   return state;
}

export default globaldataReducer;