import React from 'react'

const Star = (stars) => {
    const starsval = stars.stars;
    const ratingStar = Array.from({length:5}, (elem, index) => {
        return (
            starsval > index ? <i key={index} className="fa-solid fa-star" style={{ color:'red' }}></i> : <i key={index} className="fa-regular fa-star" style={{ color:'red' }}></i>
        )
    })
    return (
       ratingStar
    )
}

export default Star