import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-toastify';
import Star from '../../Star/Star';
import Collapse from 'react-bootstrap/Collapse';
import OwlCarousel from 'react-owl-carousel';
import Rating from '../../Home/Star';
const ServiceSidebar = ({ services_list, id, reviews }) => {
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0)
  const [username, setusername] = useState('')
  const [useremail, setuseremail] = useState('')
  const [userMessage, setuserMessage] = useState('')

  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
    dots: true,
    margin: 30,
    items: 1,
    smartSpeed: 700,
    responsive: {
      0: {
        margin: 30,
        items: 1
      },
      375: {
        margin: 30,
        items: 1
      },
      575: {
        margin: 30,
        items: 1
      },
      767: {
        margin: 50,
        items: 1
      },
      991: {
        margin: 40,
        items: 1
      },
      1199: {
        margin: 80,
        items: 1
      }
    }
  }

  const handleReviewSubmit = (event) => {
    event.preventDefault()
    axios.get('/sanctum/csrf-cookie').then((response) => {
      axios
        .post('/api/submit-review', { 'name': username, 'email': useremail, 'rating': rating, 'message': userMessage, 'service_id': id })
        .then((res) => {
          if (res.data.success === true && res.status === 200) {
            toast.success("Thank you for your kind reviews!")
            setRating(0)
            setusername('')
            setuseremail('')
            setuserMessage('')
          }
          else if (res.status === 200 && res.data.validation_success === false) {
            if (res.data.data) {
              Object.keys(res.data.data).forEach(key => {
                res.data.data[key].map(item => (
                  toast.error(item)
                ))
              })
            }
          }
          else {
            toast.error('Some error occurred. Please try again!')
          }
        })
        .catch((error) => {
          toast.error('Some error occurred');
        })
    })
  }
  return (
    <>
      <div className="sidebar mb-3">
        <div className="sidebar__item sidebar__item--category" style={{ backgroundColor: '#f9f6ff', paddingBottom: '20px' }}>
          <p
            className="sidebar__title"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            style={{ cursor: 'pointer' }}
          >See All Categories</p>
          <Collapse in={open}>
            <ul className="sidebar__category">
              {services_list && services_list.map((item, i) => (
                <li key={i}><Link to={item.slug}>{item.title}</Link></li>
              ))}
            </ul>
          </Collapse>
        </div>

        <div className="sidebar__item sidebar__item--cta">
          <div className="sidebar__cta pt-3 pb-0">
            <h3 className="sidebar__cta__title" style={{ marginBottom: '0px', paddingBottom: '10px' }}>
              <span style={{ fontSize: '18px' }}>Click Below To Get Started</span>
              <br />
            </h3>
            <Link to="#" data-bs-toggle="modal" data-bs-target="#exampleModal" className="thm-btn thm-btn--light cta-one__btn" style={{ padding: '8px 18px' }} ><span>Get a quote</span></Link>
          </div>
        </div>

        {/* Reviews */}
        {reviews && reviews.length > 0 && 
          <div className="sidebar__item sidebar__item--cta" style={{ marginTop: '0px !important' }}>
            <div className="sidebar__item sidebar__item--cta">
              <div className="sidebar__cta pt-3 pb-0" >
                <h4 className="sidebar__cta__title" style={{ marginBottom: '0px', paddingBottom: '10px' }}>
                  Reviews
                </h4>
              </div>
            </div>
            <div>
              <OwlCarousel className="slider-one__carousel owl-theme thm-owl__carousel" {...options}>
              {reviews.map((item, i) => (
                <div key={i} className="col-lg-12">
                  <div className="testimonials-one-card" style={{ paddingLeft: '47px', marginLeft: '0px' }}>
                    <div className="testimonials-one-card__content">
                      <div className="testimonials-one-card__text">
                        {item.message}
                      </div>
                      <h3 className="testimonials-one-card__title">{item.name}</h3>
                      <p className="testimonials-one-card__designation">
                        <Rating stars={item.rating} />
                      </p>
                      <i className="icon-right-quote testimonials-one-card__icon"></i>
                    </div>
                  </div>
                </div>
              ))}
                
              </OwlCarousel>
            </div>
          </div>
        }

        {/* Add review */}
        <div className="sidebar__item sidebar__item--cta" style={{ marginTop: '0px !important' }}>
          <div className="sidebar__item sidebar__item--cta">
            <div className="sidebar__cta pt-3 pb-0" >
              <h4 className="sidebar__cta__title" style={{ marginBottom: '0px', paddingBottom: '10px' }}>
                Add Review
              </h4>
            </div>
          </div>
          <div>
            <form onSubmit={handleReviewSubmit} style={{ padding: '0px !important' }} className="review-form">
              <div className='row'>
                <div className="section-title" style={{ padding: '0px', paddingTop: '10px', textAlign: 'center' }}>
                  <Star rating={rating} onRating={(rate) => setRating(rate)} />
                </div>
                <div className="col-12 mt-2">
                  <input type="text" placeholder="Your name" value={username} onChange={(e) => setusername(e.target.value)} name="name" />
                </div>
                <div className="col-12 mt-2">
                  <input type="text" placeholder="Your Email" value={useremail} onChange={(e) => setuseremail(e.target.value)} name="email" />
                </div>
                <div className='col-12 mt-2'>
                  <textarea value={userMessage} onChange={(e) => setuserMessage(e.target.value)} name='message' placeholder='Write a comment...'>
                  </textarea>
                </div>
                <div className='col-12 mt-2 m-auto'>
                  <button className="thm-btn contact-one__btn" style={{ padding: "7px 18px" }} type="submit"><span>send a
                    message</span></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>



    </>
  )
}

export default ServiceSidebar