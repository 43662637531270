import { createContext, useContext, useEffect, useReducer } from "react";
import reducer from '../reducer/globaldataReducer'
import axios from "axios";

const WebSetting = createContext();
const initialStates = {
    email: null,
    address:null,
    logo: null,
    phone_no: null,
    copyright: null,
    footer_logo:null,
    footer_info_text:null,
    our_services: [],
    social_icons: [],
    navitems: [],
}

export const WebSettingProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialStates)
    const fetchMainDetail = async () => {
        await axios.get('/api/getbasicWebdetails').then((res) => {
            return dispatch({ type: "WEBSITE_SETTINGS", payload: res.data })
        })
        .catch((error) => {
            console.log(error)
        })
    }
    useEffect(
        () => {
            fetchMainDetail();
        }, [])
    return (
        <WebSetting.Provider value={{ ...state }}>
            {children}
        </WebSetting.Provider>
    )
}

export const useWebSettingContext = () => {
    return useContext(WebSetting)
}